* {
  outline: none;
  // -ms-overflow-style: none !important;
  // /* IE and Edge */
  // scrollbar-width: none !important;

  // /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }
}

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

div,
span,
p {
  caret-color: transparent;
}

body {
  margin: 0;
  box-sizing: border-box;
}

// body::-webkit-scrollbar {
//   width: 4px;
//   background: #0a0a0a
// }

// body::-webkit-scrollbar-track {
//   background: 0 0
// }

// body::-webkit-scrollbar-thumb {
//   background: #E4700E;
//   border-radius: 16px;
// }

// body::-webkit-scrollbar-thumb:hover {
//   background: #E4700E;
// }

#root {
  position: relative;
}

.app {
  transition: all ease 0.33ms;
  font-size: 12px;
  font-family: "Poppins";
  color: #eef2f9;
}

.font-family-DMSans {
  font-family: "DM Sans";
}

.app-container {
  position: relative;
  overflow: hidden;
}

.ml-12 {
  margin-left: 12px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-44 {
  margin-top: 44px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-30 {
  font-size: 30px;
}

.font-36 {
  font-size: 36px;
}

.font-40 {
  font-size: 40px;
}

.font-48 {
  font-size: 48px;
}

.font-64 {
  font-size: 64px;
}

.color1 {
  color: #eef2f9;
}

.color2 {
  color: #ffffff;
}

.color3 {
  color: #a7a3fe;
}

.color4 {
  color: #e4700e;
}

.color5 {
  color: #8376ab;
}

.color6 {
  color: #9b96ff;
}

.color7 {
  color: #706e86;
}

.color8 {
  color: rgb(255, 255, 255, 0.5);
}

.color9 {
  color: #000000;
}

.color10 {
  color: rgba(0, 0, 0, 0.5);
}

.font-weight-b {
  font-variation-settings: "wght" 700;
  font-weight: bold;
}

.font-weight-6 {
  font-weight: 600;
}

.font-weight-5 {
  font-weight: 500;
}

.font-weight-4 {
  font-weight: 400;
}

.border-radius-4 {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
}
